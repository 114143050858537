.scan {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 30%;
    place-items: center;
    background-color: #126652;
    color: white
}
  
.scan-camera {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 30%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.scan-camera.fade-in {
    opacity: 1;
}


.loading-img {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    place-items: center;
    opacity: 1;
}

.scan-info {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 2 / 3;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
}

.scan-info.cam-start {
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1) 90%);
}

.scan-info-text {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.scan-info-text-detail {
    place-items: top;
}

.scan-info-interact {
    width: 80%;
    height: 100%;
    grid-row: 2;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-scan-info-interact {
    width: 80%;
    height: 100%;
    grid-row: 2;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.terms-text {
    padding-top: 12px;
    color: #339D7E;
    text-align: center;
    font-size: 8px;
    font-weight: 500;
}

.desktop-page {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 15% 15%;
    place-items: center;
    background-color: #f3f3f3;
    color: black
}

.desktop-info {
    grid-row: 1 / 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    width: 312px;
    background-color: #f3f3f3;
}

.desktop-title {
    font-size: 28px;
    color: black;
    font-weight: 700;
}


.desktop-detail {
    font-size: 16px;
    color: black;
    font-weight: 400;
}

.source-logo {
    grid-row: 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 312px;
}

.source-text {
    font-size: 10px;
    color: black;
    font-weight: 400;    
}