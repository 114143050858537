.form-options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    gap: 0.75em;
}

.color-options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: 1.5rem;
}

.circles-container {
    width: min(80vmin, 480px);
    height: max(10vmin, 69px);
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.circle {
    width: max(10vmin, 69px);
    height: max(10vmin, 69px);
    border-radius: 50%;
    cursor: pointer;
    border: none;
}

.selected {
    border-radius: 50%;
    border: 8px solid #50A68C;
}

.circle:focus {
    outline: none;
}

.black {
    background: linear-gradient(90deg, #232323, #595350);
}

.brown {
    background: linear-gradient(90deg, #57382B, #AC7E6A);
}

.red {
    background: linear-gradient(90deg, #803F31, #BB8A80);
}

.blonde {
    background: linear-gradient(90deg, #916D42, #EBD0B1);
}