.result {
    width: 100%;
    display: grid;
    background-color: #f3f3f3;
    scroll-behavior: smooth;
    padding-bottom: 1rem;
}

.result-short-info {
    height: 95svh;
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-rows: 80% 10% 10%;
    background-color: #f3f3f3;
}

.results-image {
    grid-row: 1 / 4;
    grid-column: 1;
    position: relative;
    height: 100%;
    width: 100%;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.vignette {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at top, transparent 60%, #f3f3f3 75%);
}

.close-icon {
    position: absolute;
    top: 5%;
    right: 10%;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: none;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    z-index: 1;
    opacity: 0;
    animation: textReveal 1s ease-in forwards;
}


.results-info {
    grid-row: 2 / 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 1;
}

.result-type {
    font-size: 0.75rem;
    font-weight: 700;
    color: black;
}

.result-decision {
    height: 3rem;
    font-size: 1.5rem;
    color: black;
}

.result-decision.irritation-present {
    color: #D74238;
}

.result-button {
    width: auto;
    padding: 10px 24px;
    height: 2rem;
    border-radius: 100px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    white-space: nowrap;
    background-color: #f9f9f9;
    border: none;
    color: black;
}

.result-button.selected {
    background-color: white;
    border: 1px solid black;
    text-wrap: nowrap;
    white-space: nowrap;
}

.result-button.green {
    background-color: #50A68C;
    color: white;
    text-wrap: nowrap;
    white-space: nowrap;
}

.result-types-button-wrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    overflow-x: scroll;
    scrollbar-width: none;
}

.result-types {
    grid-row: 3;
    grid-column: 1;
    width: 100%;
    background-color: #f3f3f3;
    z-index: 1;
    display: grid;
    justify-content: center;
}

.result-types-button-wrapper::-webkit-scrollbar {
    display: none;
}

.result-detail-info {
    width: 100%;
    padding: 0 2rem 1rem;
    background-color: #f3f3f3;
    text-align: left;
    white-space: none;
    display: grid;
    justify-content: center;
}

.result-detail-text {
    line-height: 1.5;
    text-align: left;
    color: black;
    font-size: 0.9rem;
    width: 100%;
    max-width: 480px;
}

.consultation-button {
    margin-top: 1rem;
    width: 100%;
    background-color: #50A68C;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    border-radius: 0.75rem;
    border: none;
    font-size: 1rem;
    font-weight: 700;
}

.hair-colors {
    width: 100%;
    display: flex;
    justify-content: center;
}

.hair-color-bar {
    padding: 10px 0.4rem 0;
    display: flex;
    align-self: center;
    align-items: center;
    gap: 0.4rem;
    height: 3rem;
    width: 80%;
    max-width: 440px;
}

.average-hair-color-item {
    border: none;
    border-radius: 100px;
    height: 1rem;
    flex: 2;
}

.hair-color-item{
    border: none;
    border-radius: 100px;
    height: 1rem;
    flex: 1;
}

.overlay-circles {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.skin-type-circle {
    width: 70px;
    height: 70px;
    position: absolute;
}

.skin-position-0 {
    top: 20%;
    right: -20%;
    animation: textSlideLeft .6s ease-in-out forwards;
}

.skin-position-1 {
    top: 65%;
    right: 105%;
    animation: textSlideRight .7s ease-in-out forwards;
}

.skin-position-2 {
    top: 70%;
    right: -20%;
    animation: textSlideLeft .8s ease-in-out forwards;
}


.skin-type-percentage {
    font-size: 0.75rem;
    font-weight: 700;
    color: black;
    text-align: center;
    opacity: 0;
    animation: textReveal 0.7s ease-in forwards;
}

.skin-type-text {
    font-size: 0.6rem;
    font-weight: 700;
    color: black;
    text-align: center;
    opacity: 0;
    animation: textReveal 0.7s ease-in forwards;
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}

@keyframes textSlideLeft {
    100% {
        right: 15%;
    }
}

@keyframes textSlideRight {
    100% {
        right: 70%;
    }
}