.webcam {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
}

.partial-overlay {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
}

.full-overlay {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.camera-canvas {
    z-index: 1;
}

.detection-canvas {
    z-index: 2;
}

.mask {
    z-index: 3;
}

.face-alignment {
    position: absolute;
    bottom: 10%;
}

.flash {
    z-index: 15;
    animation: cameraFlash .1s ease-in forwards;
}

@keyframes cameraFlash {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    50% {
        background-color: rgba(255, 255, 255, 0.9);
    }
    100% {
        background-color: rgba(255, 255, 255, 0);
    }
}