.head-scan {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 15% 15%;
    place-items: center;
    background-color: black;
}

.head-scan-webcam {
    grid-column: 1;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
}

.full-overlay {
    grid-column: 1;
    grid-row: 1 / 4;
    width: 100%;
    height: 100%;
    overflow: hidden;    
}

.flash {
    z-index: 10;
    animation: cameraFlash .1s ease-in forwards;
}

@keyframes cameraFlash {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    50% {
        background-color: rgba(255, 255, 255, 0.9);
    }
    100% {
        background-color: rgba(255, 255, 255, 0);
    }
}

.head-scan-tutorial-background {
    grid-row: 1 / 4;
    grid-column: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: black;
}

.overlayed {
    opacity: 0.7;
    transition: opacity 0.1s ease-in;
}

.hidden {
    opacity: 0;
}

.head-scan-img {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    place-items: center;
    z-index: 2;
}

.head-scan-text {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 10px;
    font-size: 1.5em;
    z-index: 2;
}

.head-scan-interact {
    width: 80%;
    height: 100%;
    grid-row: 3;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.round-capture-button {
    width: 80px;
    height: 80px;
    max-width: 80px;
    max-height: 80px;
    border: 6px solid lightgray;
    border-radius: 2px;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    background-color: white;
    z-index: 2;
}

.hair-tutorial-flex-bar {
    width: 100%;
    height: 15vmin;
    max-width: 480px;
    max-height: 48px;
    display: grid;
    grid-template-columns: 35% calc(65% - 10px);
    gap: 10px;
    opacity: 0;
    transform: translateY(15px);
    transition: transform .6s linear, opacity .5s linear;
}

.hair-tutorial-flex-bar.show-up-buttons {
    opacity: 1;
    transform: translateY(0px);
  }

.hair-tutorial-back-button {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(0,0,0,0);
    border: 1px solid white;
    color: white;
    font-size: 1rem;
    font-weight: 700;
}

.hair-tutorial-start-button {
    width: 100%;
    border: none;
    text-align: center;
    border-radius: 12px;
    background-color: white;
    color: #50A68C;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    font-weight: 700;
    flex-grow: 1;
}

@keyframes slideUpHairButtons {
    100% {
       transform : translateY(0px);
    }
}

@keyframes showHairButtons {
    100% {
       opacity: 1;
    }
}