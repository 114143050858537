.info-text {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 10px;
}

.white-text {
    color: white;
    font-size: 1rem;
    font-weight: 700;
}