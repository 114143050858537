.analyze {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 85% 0% 0% 0% 0% 15%;
    place-items: center;
    transition: grid-template-rows .5s ease-in-out;
}

h4 {
    padding-top: 10px;
    font-size: 1.5em;
}

p {
    color: #9e9e9e;
    font-size: 1em;
}

.split-three {
    grid-template-rows: 20% 10% 10% 30% 15% 15%;
}

.analyze-complete {
    font-size: 5em;
}

.success {
    color: #7cc2ae;
}

.failed {
    color: red;
}

.analyze-progress {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.analyze-title-success {
    margin-top: 3.5rem;
    color: black;
    font-size: 1.5rem;
    font-weight: 700;
    max-width: 273px;
    opacity: 0;
    animation: textReveal 1.2s ease-in forwards;
}

.analyze-info-success {
    color: black;
    font-size: 1rem;
    font-weight: 400;
    max-width: 273px;
    text-align: center;
    opacity: 0;
    animation: textReveal 1.2s ease-in forwards;
}

.analyze-title-failed {
    margin-top: 3.5rem;
    color: black;
    font-size: 1.25rem;
    font-weight: 700;
    max-width: 273px;
}

.analyze-info-failed {
    color: black;
    font-size: 0.75rem;
    font-weight: 500;
    max-width: 273px;
    text-align: center;
}

.analyze-text-animated {
    font-size: 1rem;
    color: #000;
    font-weight: 600
}

.move-top {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.analyze-content {
    grid-row: 3 / 6;
    grid-column: 1;
    width: 80%;
    max-width: 480px;
    height: 100%;
    display: grid;
    place-items: center;
    overflow-y: auto;
    scrollbar-width: none;
}

.analyze-content.full {
    grid-row: 2 / 6;
}

.analyze-content-text {
    grid-row: 2 / 3;
    grid-column: 1;
    width: 80%;
    max-width: 480px;
    height: 100%;
    display: grid;
    text-align: left;
}

.content-option {
    padding-top: 10px;
    width: 100%;
    height: 100%;
    max-width: 480px;
    display: flex;
}

.analyze-skip-button {
    grid-row: 5;
    grid-column: 1;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;  
}


.analyze-button {
    grid-row: 6;
    grid-column: 1;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;  
}

@keyframes textReveal {
    100% {
        opacity: 1;
    }
}