.linear-bar {
    width: 100%;
    max-width: 480px;
    height: 10px;
    border-radius: 10px;
    background: radial-gradient(circle, rgb(2, 200, 2), rgb(92, 128, 0), red);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.icon {
    position: absolute;
    font-size: 1.2em;
    margin-top: 0.8em;
    transition: left 0.1 ease, right 0.1 ease;
}

.tick {
    font-size: 1.6em;
    border-radius: 50%;
    border: 3px solid green;
    background-color: white;
    color: green;
    transition: color 0.5s linear, background-color 0.5s linear;
}

.green {
    background-color: green;
    color: white;    
}