.hair-page {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50% 20% 15% 15%;
    place-items: center;
    background-color: #126652;
    /* background: linear-gradient(to bottom, #358971, #00513C); */
}

.hair-page-img {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    place-items: center;
    opacity: 1;
}

.hair-page-text {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 10px;
}

.hair-page-skip {
    width: 80%;
    height: 100%;
    grid-row: 3;
    grid-column: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.hair-page-continue {
    width: 80%;
    height: 100%;
    grid-row: 4;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}