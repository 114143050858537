.model-card {
    background-color: white;
    border-radius: 12px;
    max-width: 480px;
}

.button-container {
    border-radius: 12px;
    display: flex;
    background-color: #f9f9f9;
    min-height: 32px;
    justify-content: space-evenly;
    overflow: hidden;
}

.tab-button {
    flex: 1;
    color: #9e9e9e;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    text-align: center;
}

.tab-button.active {
    background-color: white;
    color: black;
}

.content-container {
    padding: 0 1rem 1rem;
}

.content-text {
    width: 100%;
    line-height: 1.5;
    text-align: left;
    color: black;
    font-size: 0.9rem;
}