.email-content {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 20% 80%;
}

.email-info {
    width: 100%;
    height: 100%;
    text-align: left;
    grid-row: 1 / 2;
    grid-column: 1;
    font-size: 1em;
}

p {
    padding-top: 10px;
    color: #9e9e9e;
    font-size: 0.7em;
}

.email-input-field {
    width: 100%;
    height: 100%;
    grid-row: 2;
    grid-column: 1;
    display: grid;
    place-items: center;
}