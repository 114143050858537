.styled-button {
  width: min(80vmin, 480px);
  height: min(15vmin, 48px);
  border: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
}

.border-round-small {
  border-radius: 12px;
}

.border-round-medium {
  border-radius: 100px;
}

.styled-button.hidden-button.filled {
  opacity: 0;
  transform: translateY(15px);
  transition: transform .6s linear, opacity .5s linear;
}

.styled-button.slide-up.filled {
  opacity: 1;
  transform: translateY(0px);
}

.white-text.filled {
  background-color: #50A68C;
  color: white;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: 700;
}

.white-text.hollow {
  background-color: rgba(0,0,0,0);
  border: 1px solid white;
  color: white;
  font-size: 1rem;
  font-weight: 700;
}

.green-text.filled {
  background-color: white;
  color: #50A68C;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: 700;
}

.green-text.hollow {
  background-color: rgba(0,0,0,0);
  border: 1px solid #50A68C;
  color: #50A68C;
  font-size: 1rem;
  font-weight: 700;
}

.styled-button.filled:disabled {
  background-color: #DBDBDB;
  color: #9e9e9e;
}
